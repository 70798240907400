import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { KTIcon } from '../../../_metronic/helpers';
import { sendEmailWithResume } from '../../api';
import { useAuth } from '../auth';
import OneDriveImportModal from './OneDriveImportModal';

type ButtonRowProps = {
    preview: string;
    loading: boolean;
    currentUserEmail: string | undefined;
    fileLink: string | undefined; // Add fileLink to props type
    template: string;
    toPDF: () => void;
    handlePreview: () => void;
    handleResumeProcess: (fileLink: string) => void;
    handledownloadDocx: () => void;
    docxDownloading: boolean;
    tabButton: string;
    formating: boolean;
    bulkTab: boolean;
    selectedFiles: string[];


};

const ButtonRow: React.FC<ButtonRowProps> = ({
    preview,
    loading,
    currentUserEmail,
    fileLink, // Declare fileLink here
    template,
    toPDF,
    handlePreview,
    handleResumeProcess,
    handledownloadDocx,
    docxDownloading,
    tabButton,
    formating,
    bulkTab,
    selectedFiles,


}) => {

    const { currentUser } = useAuth()
    const [sendingMail, setSendingMail] = useState<boolean>(false);
    const [showModal, setShowModal] = useState(false);

    const [showDropdown, setShowDropdown] = useState<string>();

    const toggleDropdown = (dropDown: string) => {
        setShowDropdown(showDropdown === dropDown ? '' : dropDown); // Toggles the dropdown for the corresponding file_url
    };

    const handleButtonClick = (dropDown: string) => {
        console.log(dropDown)
        toggleDropdown(dropDown); // Toggle the dropdown visibility
    };



    console.log(formating)
    const handleSendEmail = () => {
        setSendingMail(true)
        sendEmailWithResume({
            "reciever_email": currentUser?.email,
            "file_links": fileLink,
            "template": template,
            "username": currentUser?.username
        }).then((res) => {
            // if (res.status == 200) {
            setSendingMail(false)
            alert("Mail sent successfully")

            // }
        })
    }
    useEffect(() => {
        console.log("inside buttobn row", selectedFiles.length)
    }, [selectedFiles])

    return (

        <div className="row">

            <div
                className={docxDownloading || bulkTab ? 'd-flex align-items-center justify-content-center' : 'd-none'}
                style={{
                    width: '100vw',
                    height: '100vh',
                    zIndex: 100,
                    backgroundColor: '#5e627861',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                }}
            >
            </div>
            <div
                className={loading ? 'd-flex align-items-center justify-content-center' : 'd-none'}
                style={{
                    width: '100vw',
                    height: '100vh',
                    zIndex: 100,
                    backgroundColor: '#5e627861',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                }}
            >
                <Spinner animation="border" role="status" variant="light" style={{ width: '3rem', height: '3rem' }}>
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
            <div
                className="d-flex justify-content-between my-1"
                data-kt-user-table-toolbar="base"
            >
                <div className="px-5">
                    {preview == "table" && (
                        <div>
                            <button
                                type="button"
                                className="btn btn-primary me-3 my-3 w-180px"
                                data-bs-toggle="modal"
                                data-bs-target="#kt_modal_assosiate_student"
                                disabled={loading}
                            >
                                {!loading ? (
                                    <span className="indicator-label">
                                        <KTIcon iconName="file-up" className="fs-3" /> Upload Resume
                                    </span>
                                ) : (
                                    <>

                                        <span className="indicator-progress" style={{ display: 'block' }}>
                                            Uploading...
                                        </span>
                                    </>
                                )}
                            </button>
                            {/* <button type="button"
                                className="btn btn-info me-3 my-3 w-180px "
                                data-bs-toggle="modal"
                                onClick={() => {
                                    if (currentUser?.subscribed == 'premium') {
                                        setShowModal(true);
                                    } else {
                                        alert("This feature is available for premium users only.");
                                    }
                                }
                                }>
                                Import From OneDrive
                            </button> */}
                            {/* <OneDriveImportModal show={showModal} onClose={() => setShowModal(false)} /> */}


                            <span style={{ position: 'relative', display: 'inline-block', marginRight: '1rem' }}>
                                <button

                                    onClick={() => {
                                        if (currentUser?.subscribed == 'premium') {
                                            handleButtonClick('drop');
                                        } else {
                                            alert("This feature is available for premium users only.");
                                        }
                                    }}
                                    type='button'
                                    className='btn btn-info me-3 my-3 w-180px '

                                >
                                    Import From External Source
                                    <KTIcon iconName='down' className='fs-5 m-0' />
                                </button>
                                {showDropdown === 'drop' && (
                                    <div
                                        style={{ position: 'absolute', top: 'calc(100% + 5px)', left: 0, zIndex: 10 }}
                                        className='btn btn-light btn-sm fw-bold fs-7 '
                                    >
                                        <div className='menu-item px-3'>
                                            <a
                                                className='menu-link px-3'
                                                onClick={(e) => {
                                                    alert("This feature will be added in an upcoming version.");
                                                    setShowDropdown("");
                                                }}
                                            >
                                                Import from OneDrive
                                            </a>
                                        </div>
                                        <div className='menu-item px-3'>
                                            <a
                                                className='menu-link px-3'
                                                onClick={(e) => {
                                                    alert("This feature will be added in an upcoming version.");
                                                    setShowDropdown("");
                                                }}
                                            >
                                                Import from Google Drive
                                            </a>
                                        </div>
                                    </div>
                                )}
                            </span>









                        </div>
                    )}
                    {(preview == "compare" || preview == "download") && (
                        <button
                            type="button"
                            className="btn btn-primary me-3 my-3 w-180px"
                            onClick={handlePreview}
                        >
                            Back to Resumes Library View
                        </button>
                    )}
                </div>
                <div>
                    {(preview == "compare" || preview == "download") && (
                        <>
                            {currentUserEmail === 'vp@thirdeyedata.ai' && fileLink && (
                                <button
                                    type="button"
                                    className="btn btn-primary me-3 my-3 w-200px"
                                    data-bs-toggle="modal"
                                    data-bs-target={
                                        currentUserEmail === 'vp@thirdeyedata.ai' || currentUserEmail === 'forum@thirdeyedata.ai'
                                            ? ''
                                            : '#kt_modal_select_resume'
                                    }
                                    onClick={() => currentUserEmail === 'vp@thirdeyedata.ai' && handleResumeProcess(fileLink)}
                                >
                                    <KTIcon iconName="pencil" className="fs-1" />
                                    Format
                                </button>
                            )}
                            {(preview == "compare" || preview === "download") &&
                                <>
                                    <button className="btn btn-primary me-3 my-3 w-180px " onClick={() => toPDF()}>
                                        <KTIcon iconName="cloud-download" className="fs-1" />
                                        Download PDF
                                    </button>
                                    <button disabled={docxDownloading} className="btn btn-primary me-3 my-3 w-180px " onClick={() => handledownloadDocx()}>


                                        {!docxDownloading ? (
                                            <span className="indicator-label d-flex align-items-center">
                                                <KTIcon iconName="sms" className="fs-1" /> &nbsp;
                                                Download Docx
                                            </span>
                                        ) : (
                                            <span className="indicator-progress" style={{ display: 'block' }}>
                                                Downloading <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span>
                                        )}

                                    </button>
                                </>

                            }
                            {preview == "download" && <>
                                <button className="btn btn-primary me-3 my-3 w-180px " onClick={() => { handleSendEmail() }}>
                                    {!sendingMail ? (
                                        <span className="indicator-label d-flex align-items-center">
                                            <KTIcon iconName="sms" className="fs-1" /> &nbsp;
                                            Email
                                        </span>
                                    ) : (
                                        <span className="indicator-progress" style={{ display: 'block' }}>
                                            Sending... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span>
                                    )}

                                </button>
                            </>

                            }
                        </>
                    )}
                    {

                        (tabButton != 'processed') &&
                        <button
                            type="button"
                            className="btn btn-primary me-3 my-3 w-200px"
                            onClick={(e) => handleResumeProcess('')}
                            disabled={selectedFiles.length <= 0}
                        >
                            {!bulkTab ? (
                                <p className="fs-1 fw-bolder pb-0 mb-0">
                                    <KTIcon iconName="gear" className="fs-1" /> Process
                                </p>) : (
                                <>
                                    <p className="fs-2  pb-0 mb-0">
                                        <KTIcon iconName="gear" className="fs-1 fw-bold" /> Processing..
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </p>

                                </>
                            )
                            }
                        </button>
                    }
                    {
                        currentUser?.usertype == 'superadmin' &&
                        <Link to="/statistics/resume">
                            <button type="button" className="btn btn-primary me-3 my-3 w-200px">
                                <KTIcon iconName="graph-2" className="fs-1" />
                                Analytics
                            </button>
                        </Link>
                    }
                </div>
            </div>
        </div >
    );
};

export default ButtonRow;
