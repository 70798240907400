import React, { useEffect, useState } from 'react';
import { utils, writeFile } from "xlsx";
import { KTIcon } from '../../../_metronic/helpers';
import OneDriveImportModal from './OneDriveImportModal';
import { useAuth } from '../auth';
import { useParams } from 'react-router-dom';
interface ButtonRowProps {
    handleProcess: () => void;
    handleIndexing: () => void;
    resultNew: Record<string, any>;
    responseJson: any; // Define type for responseJson as needed
    setResultView: React.Dispatch<React.SetStateAction<string>>;
    resultView: string | undefined; // Update resultView type
    tables: Array<Array<MyObject>>
    toggleState: () => void;
    preview: boolean;
    tab: string;
    processing: boolean;
    selectedFiles: string[];
    indexing: boolean;
    // Add more props as needed
}

interface MyObject {
    [key: string]: string[];
};


export const ButtonRow: React.FC<ButtonRowProps> = ({
    tables,
    preview,
    handleProcess,
    resultNew,
    responseJson,
    setResultView,
    resultView,
    tab,
    processing,
    selectedFiles,
    toggleState,
    handleIndexing,
    indexing,
}) => {
    const [closeModal, setCloseModal] = useState<boolean>(false);
    const [downloadFormat, setDownloadFormat] = useState('csv')
    const [exportFormat, setExportFormat] = useState('')
    const [downlodingJson, setDownloadingJson] = useState<boolean>(false);
    const [selectedCsv, setSelectedCsv] = useState<{ [key: string]: any }>([]);
    const [selectedFileNames, setSelectedFileNames] = useState<string[]>([]);
    const [fileName, setFileName] = useState<string>("");
    const [showModal, setShowModal] = useState(false);
    const [showDropdown, setShowDropdown] = useState<string>();

    const toggleDropdown = (dropDown: string) => {
        setShowDropdown(showDropdown === dropDown ? '' : dropDown); // Toggles the dropdown for the corresponding file_url
    };

    const handleButtonClick = (dropDown: string) => {
        console.log(dropDown)
        toggleDropdown(dropDown); // Toggle the dropdown visibility
    };

    const handleToggle = () => {
        toggleState(); // Toggle the state in the parent component
    };

    const { currentUser } = useAuth();
    const { doctype } = useParams();

    const handleDownload = () => {
        if (downloadFormat == 'csv') {
            handleCSVDownload()
        } else if (downloadFormat == 'json') {
            handleJsonDownload()
        } else if (downloadFormat == 'xlsx') {
            handleCSVDownload()
        } else if (downloadFormat == 'txt') {
            handleJsonDownload()
        } else {
            alert("Please select valid file type")
        }

    }



    useEffect(() => {
        console.log("Invoked")
    })

    const handleExport = () => {
        if (exportFormat == 'snowflake') {
            // // api call
            // setTimeout(() => {
            //     alert("Data successfully inserted into snowflake")
            // }, 2000);
        } else if (exportFormat == 'mysql') {
            alert("This feature not Supported in Trial Plan")
        } else if (exportFormat == 'crm') {
            alert("This feature not Supported in Trial Plan")
        } else if (exportFormat == 'erp') {
            alert("This feature not Supported in Trial Plan")
        } else {
            alert("Please select valid Database")
        }

    }

    const handleCSVDownload = () => {
        // Iterate over the values and create a 2D array
        console.log("tables length csv: ", tables.length);
        tables.map((docTable, i) => {
            console.log("csv index : ", i);
            console.log("csv docs : ", docTable);
            const workbook = utils.book_new();
            const worksheet = utils.json_to_sheet([selectedCsv[i]]);
            console.log(tables);
            // Iterate over the values and create a 2D array
            docTable.map((table, index) => {
                const array2D = Object.entries(table).map(([key, values]) => [
                    ...values
                ]);
                const transposeArray = (array: any[][]): any[][] =>
                    array[0].map((_, colIndex) => array.map((row) => row[colIndex]));
                const transposedArray = transposeArray(array2D);
                console.log("tanspose arr : ", transposedArray);

                const transaction = utils.aoa_to_sheet([...transposedArray]);
                utils.book_append_sheet(workbook, transaction, `sheet${index + 1}`);
            });
            utils.book_append_sheet(workbook, worksheet, "");
            downloadFormat == 'csv' ? writeFile(workbook, `${selectedFileNames[i]} Report.csv`) : writeFile(workbook, `${selectedFileNames[i]} Report.xlsx`);
        });
    };

    const handleJsonDownload = () => {
        setDownloadingJson(true)
        var data, filename, link;
        var tempJsonFile = responseJson[0]
        var tempResultJsonData: MyObject = {}
        Object.entries(resultNew).map(([key, value]) => {
            if (typeof value != "object") {
                tempResultJsonData[key] = value
            }
        })
        console.log(tempResultJsonData)
        tempJsonFile.push(tempResultJsonData)
        console.log(tempJsonFile)
        // create file in browser
        const jsonFileName = fileName.slice(0, fileName.indexOf('.pdf'));
        const json = JSON.stringify(tempJsonFile, null, 2);
        const blob = new Blob([json], { type: "application/json" });
        const href = URL.createObjectURL(blob);

        // create "a" HTLM element with href to file
        link = document.createElement("a");
        link.href = href;
        downloadFormat == 'json' ? link.download = jsonFileName + ".json" : link.download = jsonFileName + ".txt";
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);

        setDownloadingJson(false)
    }

    const handleBulkProcess = () => {
        alert("Bulk Processing is not Supported in Trial Plan")
    }

    return (
        <div className="row">
            <div
                className="d-flex justify-content-between my-1"
                data-kt-user-table-toolbar="base"
            >
                <div className="px-5 d-flex flex-wrap">
                    {
                        !preview &&
                        <div>
                            <button
                                type="button"
                                className="btn btn-primary me-3 my-3 w-180px "
                                data-bs-toggle="modal"
                                data-bs-target="#kt_modal_assosiate_student"
                                onClick={(e) => setCloseModal(false)}
                            >
                                <KTIcon iconName="plus" className="fs-3" />
                                Upload Documents
                            </button>

                            {/* <button type="button"
                                className="btn btn-info me-3 my-3 w-180px "
                                // data-bs-toggle="modal"
                                onClick={() => {
                                    handleButtonClick('drop')
                                    if (currentUser?.subscribed == 'premium') {
                                        setShowModal(true);
                                    } else {
                                        alert("This feature is available for premium users only.");
                                    }
                                }
                                }>
                                Import From External Source
                            </button> */}
                            <span style={{ position: 'relative', display: 'inline-block', marginRight: '1rem' }}>
                                <button

                                    onClick={() => {
                                        if (currentUser?.subscribed == 'premium') {
                                            handleButtonClick('drop');
                                        } else {
                                            alert("This feature is available for premium users only.");
                                        }
                                    }}
                                    type='button'
                                    className='btn btn-info me-3 my-3 w-180px '

                                >
                                    Import From External Source
                                    <KTIcon iconName='down' className='fs-5 m-0' />
                                </button>
                                {showDropdown === 'drop' && (
                                    <div
                                        style={{ position: 'absolute', top: 'calc(100% + 5px)', left: 0, zIndex: 10 }}
                                        className='btn btn-light btn-sm fw-bold fs-7 '
                                    >
                                        <div className='menu-item px-3'>
                                            <a
                                                className='menu-link px-3'
                                                onClick={(e) => {
                                                    alert("This feature will be added in an upcoming version.");
                                                    setShowDropdown("");
                                                }}
                                            >
                                                Import from OneDrive
                                            </a>
                                        </div>
                                        <div className='menu-item px-3'>
                                            <a
                                                className='menu-link px-3'
                                                onClick={(e) => {
                                                    alert("This feature will be added in an upcoming version.");
                                                    setShowDropdown("");
                                                }}
                                            >
                                                Import from Google Drive
                                            </a>
                                        </div>
                                    </div>
                                )}
                            </span>







                            {/* <OneDriveImportModal show={showModal} onClose={() => setShowModal(false)} /> */}
                        </div>
                    }
                    {
                        preview && <button
                            type="button"
                            className="btn btn-primary me-3 my-3 w-180px "
                            onClick={handleToggle}
                        >
                            Back to Document Library View
                        </button>
                    }
                </div>
                <div className='d-flex align-items-center'>
                    {tab != 'processed' &&
                        <button
                            type="button"
                            disabled={selectedFiles.length <= 0}
                            className="btn btn-primary me-3 my-3 w-200px"
                            onClick={(e) => handleProcess()}
                        >
                            {!processing ? (
                                <p className="fs-1 fw-bolder pb-0 mb-0">
                                    <KTIcon iconName="gear" className="fs-1" /> Process
                                </p>) : (
                                <>
                                    <p className="fs-2  pb-0 mb-0">
                                        <KTIcon iconName="gear" className="fs-1 fw-bold" /> Processing..
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </p>

                                </>
                            )
                            }
                        </button>
                    }

                    {/* indexing */}
                    {/* {
                        (tab!='unprocessed' && (doctype=='invoice'||doctype=='bankstatement')) &&
                        <button
                            type="button"
                            disabled={selectedFiles.length <= 0}
                            className="btn btn-primary me-3 my-3 w-200px"
                            onClick={(e) => handleIndexing()}
                        >
                            {!indexing ? (
                                <p className="fs-1 fw-bolder pb-0 mb-0">
                                    <KTIcon iconName="address-book" className="fs-1" /> Index Files
                                </p>) : (
                                <>
                                    <p className="fs-2  pb-0 mb-0">
                                        <KTIcon iconName="gear" className="fs-1 fw-bold" /> Indexing..
                                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </p>

                                </>
                            )
                            } */}
                    {/* </button> */}
                    {/* } */}
                    {(preview && Object.keys(resultNew).length !== 0) && <>
                        {
                            responseJson && <>
                                {
                                    resultView == "default" ?
                                        <button
                                            type="button"
                                            className="btn btn-primary me-3 my-3 w-180px"
                                            onClick={() => setResultView("json")}
                                        >
                                            <KTIcon iconName="data" className="fs-1" />
                                            JSON Result
                                        </button> :
                                        <button
                                            type="button"
                                            className="btn btn-primary me-3 my-3 w-180px"
                                            onClick={() => setResultView("default")}
                                        >
                                            <KTIcon iconName="menu" className="fs-1" />
                                            Table Result
                                        </button>
                                }
                            </>
                        }
                    </>
                    }
                    {/* <div className="dropdown me-3">
                            <button className="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <KTIcon iconName="document" className="fs-1" />
                                Export To
                            </button>
                            <ul className="dropdown-menu">
                                <li><a className="dropdown-item" href="#">Dataverse</a></li>
                                <li><a className="dropdown-item" href="#">MySql</a></li>
                                <li><a className="dropdown-item" href="#">SAP</a></li>
                            </ul>
                        </div> */}

                    {(doctype === 'invoice' || doctype === 'bankstatement') && (
                        <button
                            className="btn btn-success me-3 my-3 w-150px"
                            id="kt_drawer_chat_toggle"
                        >
                            <KTIcon iconName="message-text-2" className="fs-1" />
                            Chat
                        </button>
                    )}


                    {/* <button
                        type="button"
                        className='btn btn-primary me-3 my-3 w-200px'
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                    >

                        <KTIcon iconName='arrow-down' className='fs-2' /> Download
                    </button> */}
                </div>
            </div>
        </div>
    );
};

